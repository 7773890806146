import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import Content from "../components/common/Content";
import Post from "../components/common/Post";
import PostNavigation from "../components/common/PostNavigation";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />
        <Content>
          <Post
            title={post.frontmatter.title}
            date={post.frontmatter.date}
            html={post.html}
          />
          <PostNavigation previous={previous} next={next} />
        </Content>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
