import cx from "classnames";
import { Link } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import styles from "./PostNavigation.module.scss";

const PostNavigation = ({ className, previous, next }) => (
  <nav className={cx(styles.root, className)}>
    <ul className={styles.list}>
      <li className={styles.listItem}>
        {previous && (
          <Link className={styles.link} to={previous.fields.slug} rel="prev">
            ← {previous.frontmatter.title}
          </Link>
        )}
      </li>
      <li className={styles.listItem}>
        {next && (
          <Link className={styles.link} to={next.fields.slug} rel="next">
            {next.frontmatter.title} →
          </Link>
        )}
      </li>
    </ul>
  </nav>
);

const postShape = PropTypes.shape({
  fields: PropTypes.shape({
    slug: PropTypes.string,
  }),
  frontmatter: PropTypes.shape({
    title: PropTypes.string,
  }),
});

PostNavigation.propTypes = {
  className: PropTypes.string,
  previous: postShape,
  next: postShape,
};

export default PostNavigation;
